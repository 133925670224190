import {
	SchedulePlatforms,
	type PlatformChannel,
	type PlatformSelection,
	type SchedulePlatform,
} from "@/typings/githubActionsHandler"
import React, { useMemo } from "react"
import DiscordIntegrationCard from "../Discord/DiscordIntegrationCard.js"
import EmailIntegrationCard from "../Email/EmailIntegrationCard.js"
import SlackIntegrationCard from "../Slack/SlackIntegrationCard.js"

interface ReportingChannelsProps {
	platforms: PlatformSelection[]
	setPlatforms: React.Dispatch<React.SetStateAction<PlatformSelection[]>>
	emails: string[]
	onEmailsChange: (emails: string[]) => void
}

interface PlatformComponentProps {
	readonly channel: PlatformChannel["id"]
	readonly onChangeChannel: (channel: PlatformChannel["id"]) => void
	readonly isSelected: boolean
	readonly onSelectChange: (e: boolean) => void
}

const platformComponents: Record<
	SchedulePlatform,
	React.FC<PlatformComponentProps>
> = {
	SLACK: SlackIntegrationCard,
	DISCORD: DiscordIntegrationCard,
}

const ReportingPlatforms: React.FC<ReportingChannelsProps> = ({
	platforms,
	setPlatforms,
	emails,
	onEmailsChange,
}) => {
	const allPlatforms = useMemo(
		() =>
			SchedulePlatforms.map(
				schedulePlatform =>
					platforms.find(
						platform => platform.platform === schedulePlatform,
					) ?? {
						platform: schedulePlatform,
						selected: false,
						channel: "",
					},
			),
		[platforms],
	)

	return (
		<div>
			<div>
				<div className="font-500 mt-2 font-poppins">Channels</div>
				<div className="max-w-lg font-poppins text-sm text-muted-foreground">
					Enable the channel to which the Custom Report applies.
				</div>
			</div>

			<div className="mt-4 flex flex-col flex-wrap gap-4 lg:flex-row">
				{allPlatforms.map(platform => {
					const PlatformComponent = platformComponents[platform.platform]

					return (
						<div key={platform.platform} className="flex-1">
							<PlatformComponent
								channel={platform.channel}
								onChangeChannel={channel => {
									setPlatforms(
										allPlatforms.map(p =>
											p.platform === platform.platform
												? { ...p, channel, selected: true }
												: { ...p, selected: false },
										),
									)
								}}
								isSelected={platform.selected}
								onSelectChange={selected => {
									setPlatforms(
										allPlatforms.map(p => {
											if (p.platform === platform.platform) {
												return { ...p, selected, channel: p.channel }
											}

											if (selected) {
												return { ...p, selected: false }
											}

											return p
										}),
									)
								}}
							/>
						</div>
					)
				})}
			</div>

			<div className="mt-4 flex flex-col flex-wrap gap-4 xl:flex-row">
				<div className="flex-1">
					<EmailIntegrationCard
						emails={emails}
						onEmailsChange={onEmailsChange}
					/>
				</div>
				<div className="flex-1" />
			</div>
		</div>
	)
}

export default ReportingPlatforms
