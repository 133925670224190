import { Button } from "@/components/ui/button.tsx"
import { loginRequest, msalConfig } from "@/pages/Login/configs/azureDevOps.ts"
import { trpc } from "@/trpc.ts"
import type { BillingHandlerCheckAndCreateUserResp } from "@/typings/billingHandler.ts"
import type {
	CRHandlerCreateOrganizationsBody,
	CRHandlerCreateOrganizationsResp,
} from "@/typings/coderabbitHandler.ts"
import { addGrowsurfParticipant } from "@/utils/growsurf-util.ts"
import { PROVIDERS, useProvider } from "@/utils/providers"
import { getCookie, sentryCaptureException } from "@/utils/utils.ts"
import * as msal from "@azure/msal-browser"
import MarkunreadIcon from "@mui/icons-material/Markunread"
import type { AxiosResponse } from "axios"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { SiAzuredevops } from "react-icons/si"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import validator from "validator"
import Loader from "../../components/Loader/Loader"
import LogoFull from "../../svg/logo-full"
import {
	generateMergeFields,
	handleEmailSubmit,
	updateMailChimpUtm,
} from "./emailHandler"
import { createTrackingCookies, getQueryParams } from "./trackingCookies"

const azurePublicClientApp = new msal.PublicClientApplication(msalConfig)

export function AzureLogin({ isCRSelfHostedLogin = false }) {
	const navigate = useNavigate()
	const [loader, setLoader] = useState<boolean>(false)
	const [loaderMessage, setLoaderMessage] = useState<string>("")
	const [isTrialUser, setIsTrialUser] = useState<boolean>(false)
	const [showEmailInput, setShowEmailInput] = useState<boolean>(false)
	const [email, setEmail] = useState<string>("")
	const [isValidEmail, setIsValidEmail] = useState<boolean>(true)
	const [showCheckmark, setShowCheckmark] = useState<boolean>(false)
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
	const [codeParameter, setCodeParameter] = useState<string>("")
	const [stateParameter, setStateParameter] = useState<string>("")
	const { provider, setProvider, isCRSelfHosted } = useProvider()

	const trpcUtils = trpc.useUtils()

	const addHubspotContact = trpc.hubspot.addContact.useMutation()

	function handleEmailInputVisibility() {
		setShowEmailInput(true)
	}

	function handleEmailInputChange(event: React.ChangeEvent<HTMLInputElement>) {
		const inputEmail = event.target.value
		setEmail(inputEmail)
		setIsValidEmail(validator.isEmail(inputEmail))
		setIsSubmitted(false)
	}

	async function handleSubmit() {
		await handleEmailSubmit(
			email,
			isValidEmail,
			setShowEmailInput,
			setIsSubmitted,
			setShowCheckmark,
		)
	}

	function navigateTo(path: string, email?: string) {
		navigate(isCRSelfHosted ? "/settings/account/subscription" : path, {
			state: { email },
		})
	}

	useEffect(() => {
		const { codeParams, stateParam, trialParams, ...trackingQueryParams } =
			getQueryParams()
		createTrackingCookies(trackingQueryParams)

		if (trialParams) setIsTrialUser(true)
		if (codeParams) setCodeParameter(codeParams)
		if (stateParam) setStateParameter(stateParam) // a.k.a provider

		const sessionAccessToken = sessionStorage.getItem("accessToken")

		if (
			!sessionAccessToken &&
			sessionStorage.getItem("login-loader") != null &&
			sessionStorage.getItem("login-loader") == "true" &&
			provider === PROVIDERS.AZURE_DEVOPS &&
			!codeParameter
		) {
			setLoader(true)
			setLoaderMessage("Authorizing your login... 🚀")

			void azurePublicClientApp.initialize().then(() => {
				void azurePublicClientApp
					.handleRedirectPromise()
					.then(resp => {
						console.log("Azure login response: ", resp)
						if (!resp) {
							return
						}

						if (resp.accessToken) {
							setCodeParameter(resp.accessToken)
						}
					})
					.catch(error => {
						console.error("Error handling redirect promise: ", error)
					})
			})
		} else if (codeParameter && sessionAccessToken === null) {
			if (
				sessionStorage.getItem("login-loader") != null &&
				sessionStorage.getItem("login-loader") == "true"
			) {
				setLoader(true)
				setLoaderMessage("Authorizing your login... 🚀")
			}

			void getAccessToken()
		} else if (
			sessionStorage.getItem("accessToken") !== null &&
			sessionStorage.getItem("subscriber_id") !== null
		) {
			let userEmail = ""
			async function fetchUserData() {
				const { user_email } = await getUserData()
				userEmail = user_email
			}

			void fetchUserData()
			navigateTo("/settings/repositories", userEmail)
		}
	}, [codeParameter, stateParameter])

	async function getAccessToken() {
		setLoader(true)
		setLoaderMessage("Authorizing your login... 🚀")
		const redirectUri =
			stateParameter === PROVIDERS.GITLAB ||
			stateParameter === PROVIDERS.GITLAB_SELF_HOSTED ||
			stateParameter === PROVIDERS.GITHUB_SELF_HOSTED ||
			provider === PROVIDERS.AZURE_DEVOPS
				? `${window.location.origin}/login`
				: ""

		try {
			const { data, status } = await trpcUtils.accessToken.getAccessToken.fetch(
				{
					provider: stateParameter || provider || "",
					redirectUri: redirectUri,
					code: codeParameter,
					selfHostedDomain: undefined,
				},
				{},
			)

			if (status !== 200) {
				toast.error("Something went wrong, Please try again")
				setLoader(false)
				return
			}

			sessionStorage.setItem("accessToken", data.token)
			sessionStorage.setItem("auth-token", data.token)
			setProvider((stateParameter || provider) as typeof provider)

			const { recently_created, user_email } = await getUserData()
			const orgExists = await checkOrg()
			if (!orgExists) await createOrg()

			if (recently_created) {
				// only add the user to hubspot for PROD
				if (import.meta.env.PROD) {
					const utmParams = generateMergeFields(false)
					await addHubspotContact
						.mutateAsync({
							email: user_email,
							provider: stateParameter,
							utmParams: {
								...utmParams,
							},
						})
						.catch(error => {
							console.error("Error adding Hubspot contact:", error)
						})
				}
				navigateTo("/onboarding", user_email)
			} else {
				navigateTo("/settings/repositories", user_email)
			}
		} catch (error) {
			toast.error("Something went wrong, Please try again")
		} finally {
			setLoader(false)
		}
	}

	async function getUserData() {
		setLoaderMessage("Setting up CodeRabbit... 🚀")
		const selfHostedDomain = sessionStorage.getItem("selfHostedDomain")
		const stateParam = sessionStorage.getItem("provider")?.replace(/"/g, "")
		const grsf = getCookie("grsf")

		let baseURL = `${
			import.meta.env.VITE_BILLING_FUNC_URL
		}/checkAndCreateUser?provider=${stateParam}&grsf=${grsf}`

		if (
			stateParam === PROVIDERS.GITLAB_SELF_HOSTED ||
			stateParam === PROVIDERS.GITHUB_SELF_HOSTED
		) {
			baseURL += `&selfHostedDomain=${selfHostedDomain}`
		}

		let recently_created = false
		let user_email = ""
		await axios
			.get<BillingHandlerCheckAndCreateUserResp>(baseURL, {
				headers: {
					Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
				},
			})
			.then(response => {
				const data = response.data
				sessionStorage.setItem("user_id", data.data.provider_user_id)
				sessionStorage.setItem("login", data.data.user_name)
				sessionStorage.setItem("subscriber_id", data.data.id)
				sessionStorage.setItem("scope", data.data.scope)
				sessionStorage.setItem(
					"recently_created",
					data.data.recently_created.toString(),
				)
				recently_created = data.data.recently_created

				if (stateParam === PROVIDERS.AZURE_DEVOPS) {
					// TODO check why we don't have the avatar anymore
					// Azure Devops proovide Base64 encoded avatar_url
					sessionStorage.setItem(
						"avatar_url",
						`data:image/png;base64, ${data.data.avatar_url}`,
					)
				} else {
					sessionStorage.setItem("avatar_url", data.data.avatar_url)
				}

				// add in growsurf
				addGrowsurfParticipant(
					data.data.email,
					data.data.name || data.data.user_name,
				)
				user_email = data.data.email
			})
			.catch(error => {
				console.error(error)
			})

		if (recently_created) {
			await updateMailChimpUtm().catch(error => {
				console.error(error)
				sentryCaptureException("updateMailChimpUtm API failed: ", error)
			})
		}

		return { recently_created, user_email }
	}

	async function checkOrg(): Promise<boolean> {
		const selfHostedDomain = sessionStorage.getItem("selfHostedDomain")
		const provider = sessionStorage.getItem("provider")?.replace(/"/g, "")
		const user_id = sessionStorage.getItem("user_id")

		if (!user_id || !provider) {
			return false
		}

		try {
			const response = await trpcUtils.organizations.checkOrganization.fetch({
				provider_organization_id: user_id,
				provider: provider,
				selfHostedDomain: selfHostedDomain ?? undefined,
			})
			return response.isSuccess
		} catch {
			return false
		}
	}

	const createOrg = async () => {
		const selfHostedDomain = sessionStorage.getItem("selfHostedDomain")

		const organization_name = sessionStorage.getItem("login")
		const provider = sessionStorage.getItem("provider")?.replace(/"/g, "")
		const provider_organization_id = sessionStorage.getItem("user_id")
		const scope = sessionStorage.getItem("scope")

		if (!organization_name || !provider_organization_id) {
			return
		}

		const body: CRHandlerCreateOrganizationsBody = {
			organization_name,
			provider_organization_id,
			provider: provider || "",
			scope,
			provider_user_id: provider_organization_id, // orgId and userId is same at login page
			memberCount: 1, // user account/personal org
		}

		if (
			provider === PROVIDERS.GITLAB_SELF_HOSTED ||
			provider === PROVIDERS.GITHUB_SELF_HOSTED
		) {
			body.selfHostedDomain = selfHostedDomain
		}

		await axios
			.post<
				CRHandlerCreateOrganizationsResp,
				AxiosResponse<CRHandlerCreateOrganizationsResp>,
				CRHandlerCreateOrganizationsBody
			>(
				`${import.meta.env.VITE_CODERABBIT_FUNC_URL}/createOrganizations`,
				body,
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem("auth-token")}`,
					},
				},
			)
			.then(() => {
				sessionStorage.removeItem("scope")
			})
			.catch(error => {
				toast.error(error.response.data.message)
				sentryCaptureException("createOrg: API failed: ", error)
			})
	}

	async function loginWithAzureDevops() {
		try {
			sessionStorage.setItem("isCRSelfHosted", isCRSelfHostedLogin.toString())
			sessionStorage.setItem("login-loader", "true")
			setProvider(PROVIDERS.AZURE_DEVOPS)

			await azurePublicClientApp.initialize()
			await azurePublicClientApp.loginRedirect(loginRequest)
		} catch (e) {
			console.error("Error during Azure DevOps login: ", e)
			toast.error("Failed to initiate Azure DevOps login. Please try again.")
		}
	}

	return (
		<>
			{loader ? (
				<Loader message={loaderMessage} />
			) : (
				<div className="flex h-screen flex-col items-center justify-center bg-secondary">
					<div className="mx-4 flex w-full max-w-xl flex-col items-center rounded-md bg-white text-center shadow-lg sm:mx-auto">
						<div className="px-4 py-16">
							<div className="flex items-center justify-center pb-5">
								<LogoFull width={171} />
							</div>

							<div className="mb-4 mt-2">
								<div className="font-500 mb-2 font-poppins text-2xl leading-8 text-foreground">
									Welcome to CodeRabbit {isCRSelfHostedLogin && "Self-Hosted"}
								</div>
								{isTrialUser && !isCRSelfHostedLogin && (
									<div className="font-400 font-poppins text-sm leading-5 text-muted-foreground">
										Get a 14-day free trial for your entire team by signing up
										with your Git provider.
									</div>
								)}
							</div>

							{!isCRSelfHostedLogin && (
								<>
									<Button
										onClick={loginWithAzureDevops}
										variant="outline"
										className="mt-3 w-full max-w-80 text-secondary-foreground"
									>
										<SiAzuredevops className="mr-2 flex-shrink-0" size={20} />
										{isTrialUser
											? "Signup with Azure Devops"
											: "Login with Azure Devops"}
									</Button>
								</>
							)}

							{/* Only in mobile view, add a button to signup via email */}
							{isTrialUser && !isCRSelfHostedLogin && (
								<div className="sm:hidden">
									<p className="mt-2 text-gray-500">or</p>
									{!isValidEmail && isSubmitted && (
										<p className="text-xs text-red-500">
											Please enter a valid email address
										</p>
									)}
									{/* Display checkmark if the email is submitted */}
									{showCheckmark ? (
										<span
											role="img"
											aria-label="Checkmark"
											className="text-green-500"
										>
											&#10004; Submitted
										</span>
									) : !showEmailInput ? (
										<button
											onClick={handleEmailInputVisibility}
											className="btn-secondary btn-secondary-outline mt-5 inline-flex items-center justify-center rounded-full border border-transparent px-2 py-2 text-sm font-bold text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 md:px-2 md:py-2 lg:px-4 lg:py-2"
											style={{ width: "12rem" }}
											type="button"
										>
											<MarkunreadIcon className="text-white-600 mr-2 inline-block h-5 w-5 align-text-top" />
											Sign up later
										</button>
									) : null}
								</div>
							)}

							{/* Render email input when the button/link is clicked */}
							{showEmailInput && (
								<div className="sm:hidden">
									<input
										type="email"
										placeholder="Enter your email"
										value={email}
										onChange={handleEmailInputChange}
										className="mt-4 rounded border px-2 py-1"
									/>
									<button
										onClick={handleSubmit}
										className="btn-secondary btn-secondary-outline mt-5 inline-flex items-center justify-center rounded-full border border-transparent px-16 py-2 text-sm font-bold text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 md:px-2 md:py-2 lg:px-4 lg:py-2"
									>
										Submit
									</button>
								</div>
							)}
						</div>

						{import.meta.env.VITE_ENABLE_CODERABBIT_SELF_HOSTED &&
							!isCRSelfHostedLogin && (
								<>
									<hr className="w-full" />

									<p className="font-400 m-4 text-center text-sm text-foreground">
										Looking for{" "}
										<Link
											to="/login/coderabbit-self-hosted"
											className="text-crb-primary-dark hover:underline"
										>
											CodeRabbit Self-Hosted login
										</Link>
										?
									</p>
								</>
							)}
					</div>

					<p className="font-400 m-3 text-center text-sm text-muted-foreground">
						By continuing, you agree to the{" "}
						<a
							href="https://coderabbit.ai/terms-of-service"
							target="_blank"
							className="text-crb-primary-dark hover:underline"
							rel="noreferrer"
						>
							Terms of Use
						</a>{" "}
						applicable to CodeRabbit
					</p>
				</div>
			)}
		</>
	)
}
export default AzureLogin
