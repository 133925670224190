import { sentryCaptureException } from "./utils"

export function addGrowsurfParticipant(email: string, name: string) {
	if (window.growsurf) {
		const { growsurf } = window
		const refererID = growsurf.getReferrerId()
		growsurf
			.addParticipant({
				email: email,
				firstName: name,
				referredBy: refererID,
			})
			.catch((error: unknown) => {
				sentryCaptureException(
					`Growsurf addParticipant failed for email: ${email}`,
					error,
				)
			})
	}
}
