import { useProvider } from "@/utils/providers"
import type React from "react"
import {
	LuBook,
	LuBookOpen,
	LuBuilding2,
	LuDatabase,
	LuDollarSign,
	LuFileBarChart2,
	LuGift,
	LuGitlab,
	LuKeyRound,
	LuLayoutGrid,
	LuPuzzle,
	LuUsers2,
} from "react-icons/lu"
import { SiAzuredevops } from "react-icons/si"

export interface SidebarMenuItem {
	/** Name of the menu item which is shown in the sidebar */
	name: string
	/** Icon for the menu item */
	Icon: React.FC<{ className?: string }>
	/** Link or function that should be executed when the menu item is clicked */
	link?: string | (() => void) | undefined
	/** Submenu items of the menu item */
	items?:
		| {
				name: string
				link: string
				hide?: boolean | (() => boolean)
				beta?: boolean
		  }[]
		| undefined
	/** Alternate names and links for the menu item to be marked as active */
	alt?: { name: string; link: string }[]
	/** Whether to hide the menu item or not */
	hide?: boolean | (() => boolean)
	/** Whether to open the link in a new tab or not */
	newTab?: boolean
}

export const NavItems: SidebarMenuItem[] = [
	{
		name: "Dashboard",
		Icon: ({ className }) => <LuLayoutGrid className={className} />,
		link: "/dashboard",
	},
	{
		name: "Repositories",
		Icon: ({ className }) => <LuDatabase className={className} />,
		link: "/settings/repositories",
		alt: [{ name: "Repository settings", link: "/repository" }],
	},
	{
		name: "Integrations",
		Icon: ({ className }) => <LuPuzzle className={className} />,
		link: "/integrations",
	},
	{
		name: "Reports",
		Icon: ({ className }) => <LuFileBarChart2 className={className} />,
		items: [
			/* { name: 'On Demand', link: '/reports/ondemand' }, */
			{ name: "Recurring", link: "/reports/recurring", beta: true },
		],
		hide: !import.meta.env.VITE_FEATURE_REPORTING_ENABLED,
	},
	{
		name: "Learnings",
		Icon: ({ className }) => <LuBookOpen className={className} />,
		link: "/learnings",
	},

	{
		name: "Organization Settings",
		Icon: ({ className }) => <LuBuilding2 className={className} />,
		items: [
			{ name: "Configuration", link: "/settings/organization" },
			{
				name: "API Keys",
				link: "/settings/api-keys",
				hide: () => {
					const { isSelfHosted } = useProvider()
					return !import.meta.env.VITE_FEATURE_API_KEYS_ENABLED || isSelfHosted
				},
			},
			{
				name: "GitLab User",
				link: "/settings/gitlab-user",
				hide: () => {
					const { isGitlab, isSelfHosted } = useProvider()
					return !isGitlab || isSelfHosted
				},
			},
			{
				name: "Azure User",
				link: "/settings/azure-user",
				hide: () => {
					const { isAzureDevops, isSelfHosted } = useProvider()
					return !isAzureDevops || isSelfHosted
				},
			},
		],
	},
	{
		name: "Subscription",
		Icon: ({ className }) => <LuDollarSign className={className} />,
		link: "/settings/subscription",
		hide: () => {
			const { isSelfHosted } = useProvider()
			return isSelfHosted
		},
	},
]

export const BottomNavItems: SidebarMenuItem[] = [
	{
		name: "Account",
		Icon: ({ className }) => <LuUsers2 className={className} />,
		link: "/settings/account/subscription",
		hide: () => {
			const { isSelfHosted } = useProvider()
			return !isSelfHosted
		},
	},
	{
		name: "Refer and Earn",
		Icon: ({ className }) => <LuGift className={className} />,
		link: () => {
			if (window.growsurf) {
				window.growsurf.open()
			}
		},
		hide: () => {
			const isActiveSubscription = sessionStorage.getItem("enable_referral")
			return (
				import.meta.env.VITE_ENABLE_REWARD_SIDEBAR !== "true" ||
				!isActiveSubscription
			)
		},
	},
	{
		name: "Docs",
		Icon: ({ className }) => <LuBook className={className} />,
		link: "https://docs.coderabbit.ai/",
		newTab: true,
	},
]

export const AccountNavItems: SidebarMenuItem[] = [
	{
		name: "Subscription",
		Icon: ({ className }) => <LuDollarSign className={className} />,
		link: "/settings/account/subscription",
	},
	{
		name: "API Keys",
		Icon: ({ className }) => <LuKeyRound className={className} />,
		link: "/settings/account/api-keys",
		hide: !import.meta.env.VITE_FEATURE_API_KEYS_ENABLED,
	},
	{
		name: "GitLab User",
		Icon: ({ className }) => <LuGitlab className={className} />,
		link: "/settings/account/gitlab-user",
		hide: () => {
			const { isGitlab } = useProvider()
			return !isGitlab
		},
	},
	{
		name: "Azure User",
		Icon: ({ className }) => <SiAzuredevops className={className} />,
		link: "/settings/account/azure-user",
		hide: () => {
			const { isAzureDevops } = useProvider()
			return !isAzureDevops
		},
	},
]
