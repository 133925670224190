import { IoMdAddCircleOutline } from "react-icons/io"

interface ImageButtonProps {
	readonly title: string
	readonly onClick?: () => void
	readonly image?: React.ReactNode
}

const ImageButton: React.FC<ImageButtonProps> = ({
	title,
	onClick,
	image = (
		<IoMdAddCircleOutline className="-ml-1 mb-0.5 mr-1 inline" size={20} />
	),
}) => {
	return (
		<button
			type="button"
			className="font-interSemibold mb-2 rounded-md bg-crb-primary px-4 py-2 text-sm font-normal text-white transition hover:bg-crb-primary-dark focus:outline-none focus:ring-4 focus:ring-[#ff8e6252] dark:focus:ring-[#ff8e6252]"
			onClick={onClick}
		>
			{image} {title}
		</button>
	)
}

export default ImageButton
