import axios from "axios"
import React, { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Loader from "../../../../components/Loader/Loader"
import LogoFull from "../../../../svg/logo-full"
import type { GHHandlerSaveSlackConnectionResp } from "../../../../typings/githubActionsHandler"
import { getSelectedOrg } from "@/utils/utils.ts"

const SlackAppInstall: React.FC = () => {
	const navigate = useNavigate()
	const { search } = useLocation()

	const [hasError, setHasError] = React.useState<boolean>(false)

	const selectedOrganization = getSelectedOrg()

	useEffect(() => {
		const params = new URLSearchParams(search)

		axios
			.post<GHHandlerSaveSlackConnectionResp>(
				`${import.meta.env.VITE_GITHUB_FUNC_URL}/saveSlackConnection`,
				{
					code: params.get("code"),
					orgId: params.get("state"),
				},
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
						"x-coderabbit-organization": selectedOrganization?.id,
					},
				},
			)
			.then(() => {
				navigate("/reports/recurring/new")
			})
			.catch(error => {
				setHasError(true)
				toast.error(
					"Failed to save slack connection: " + error.response?.data?.message ||
						error,
				)
				console.error("Failed to save slack connection:", error)
			})
	}, [])

	if (hasError) {
		return (
			<div className="flex h-screen w-screen flex-col items-center justify-center ">
				<LogoFull width={350} />
				<h1 className="mt-4 font-poppins text-lg text-[#242424] sm:text-xl">
					Failed to connect to Slack. Please try again.
				</h1>
			</div>
		)
	}

	return <Loader message="Connecting Slack..." />
}

export default SlackAppInstall
