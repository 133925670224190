import React, { useCallback, useState } from "react"
import { LuChevronDown, LuChevronUp } from "react-icons/lu"
import { Link, useLocation } from "react-router-dom"
import type { SidebarMenuItem } from "./Nav"
import { isNotHidden } from "./nav-utils"

interface MenuItemProps extends SidebarMenuItem {
	readonly isActive?: boolean
	readonly isCollapsed?: boolean
}

const MenuItem: React.FC<MenuItemProps> = React.memo(
	({
		name,
		Icon,
		link,
		items = [],
		newTab = false,
		isActive = false,
		isCollapsed = false,
	}) => {
		const [isExpanded, setIsExpanded] = useState<boolean>(
			isActive && items.length > 0,
		)

		const toggleDropdown = useCallback(() => {
			if (items.length > 0) {
				setIsExpanded(!isExpanded)
			}
		}, [items.length, isExpanded])

		const onClick = useCallback(() => {
			if (typeof link === "function") {
				link()
			}

			toggleDropdown()
		}, [link, toggleDropdown])

		return (
			<>
				<LinkItem
					to={link}
					className={`group relative block cursor-pointer rounded-md px-4 py-3 ${isActive ? "bg-accent" : "hover:bg-accent"}`}
					onClick={onClick}
					title={isCollapsed ? name : ""}
					newTab={newTab}
				>
					<div className="flex w-full items-center justify-between">
						<div className="flex items-center">
							<Icon
								className={`flex-shrink-0 text-xl text-accent-foreground`}
							/>
							{!isCollapsed && (
								<span className={`font-500 ml-3 pr-1 font-inter`}>{name}</span>
							)}
						</div>

						{/* Submenu expand/collapse icon */}
						{!isCollapsed && items.length > 0 && (
							<div className="transition-transform duration-200">
								{isExpanded ? (
									<LuChevronUp size={20} />
								) : (
									<LuChevronDown size={20} />
								)}
							</div>
						)}
					</div>

					{isCollapsed && items.length > 0 && (
						<div className="absolute left-full top-0 z-20 hidden min-w-52 rounded-lg border bg-white shadow-lg group-hover:block">
							<SubMenuItems items={items} />
						</div>
					)}
				</LinkItem>

				{/* Submenu items */}
				{!isCollapsed && isExpanded && <SubMenuItems items={items} />}
			</>
		)
	},
)

const SubMenuItems = React.memo(
	({ items }: { items: SidebarMenuItem["items"] }) => {
		const { pathname } = useLocation()

		const checkIsActive = (subItem: { link: string }) =>
			pathname.startsWith(subItem.link)

		return (
			<>
				{items?.filter(isNotHidden).map(subItem => (
					<LinkItem
						key={subItem.name}
						to={subItem.link}
						className="group/subitem flex w-full items-center"
					>
						<span
							className={`font-500 flex-1 rounded-md py-3 pl-12 pr-1 font-inter
							${checkIsActive(subItem) ? "bg-accent" : "group-hover/subitem:bg-accent"}`}
						>
							<div className="flex flex-wrap items-center justify-between gap-2">
								{subItem.name}
								{subItem.beta && (
									<div className="mr-2 w-fit rounded-full border px-2 py-0.5 text-xs text-muted-foreground">
										Beta
									</div>
								)}
							</div>
						</span>
					</LinkItem>
				))}
			</>
		)
	},
)

SubMenuItems.displayName = "SubMenuItems"

const LinkItem: React.FC<{
	to?: string | (() => void) | undefined
	className?: string
	onClick?: (e: React.MouseEvent) => void
	title?: string
	newTab?: boolean
	children: React.ReactNode
}> = ({ to, className, onClick, title, newTab, children }) => {
	if (typeof to === "string") {
		return (
			<Link
				to={to}
				className={className}
				onClick={onClick}
				title={title}
				target={newTab ? "_blank" : "_self"}
			>
				{children}
			</Link>
		)
	}

	return (
		<div className={className} onClick={onClick} title={title}>
			{children}
		</div>
	)
}

MenuItem.displayName = "MenuItem"

export default MenuItem
