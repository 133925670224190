import Chip from "@/components/Chip/Chip"
import Combobox from "@/components/Combobox/Combobox"
import { trpc } from "@/trpc"
import type { CRHandlerGetInstalledReposResp } from "@/typings/coderabbitHandler"
import type {
	BaseSchedule,
	ScheduleOperator,
	ScheduleParameter,
} from "@/typings/githubActionsHandler"
import {
	ScheduleOperators,
	ScheduleParameters,
} from "@/typings/githubActionsHandler"
import { useProvider } from "@/utils/providers"
import {
	capitalizeFirstLetter,
	getSelectedOrg,
	promiseFulfilled,
	promiseRejected,
} from "@/utils/utils"
import axios from "axios"
import React, { useEffect, useMemo, useState } from "react"
import { CgSpinner } from "react-icons/cg"
import { FaRegTrashAlt } from "react-icons/fa"
import { IoMdAddCircleOutline } from "react-icons/io"
import { toast } from "react-toastify"

interface ParameterFilter {
	id: string
	name: string
}

type ParameterFilterValues = {
	[K in ScheduleParameter]?: {
		operators: ScheduleOperator[]
		loading?: boolean
		values: ParameterFilter[]
	}
}

interface ReportingParametersProps {
	selectedParameters: BaseSchedule["parameters"]
	setSelectedParameters: React.Dispatch<
		React.SetStateAction<BaseSchedule["parameters"]>
	>
}

const ReportingParameters: React.FC<ReportingParametersProps> = ({
	selectedParameters,
	setSelectedParameters,
}) => {
	const selectedOrg = getSelectedOrg()
	const { isGitlab } = useProvider()
	const trpcUtils = trpc.useUtils()

	const scheduleParameters = useMemo(
		() => ScheduleParameters.filter(param => !(isGitlab && param === "TEAM")),
		[isGitlab],
	)
	const MAX_PARAMETERS = scheduleParameters.length

	const [parameterFilterValues, setParameterFilterValues] =
		useState<ParameterFilterValues>(() => {
			const paramFilterValues: ParameterFilterValues = {
				REPOSITORY: {
					operators: ["IN"],
					values: [],
				},
				LABEL: {
					operators: ["IN", "ALL"],
					values: [],
				},
				TEAM: { operators: ["IN"], values: [] },
				USER: {
					operators: ["IN"],
					values: [],
				},
			}

			if (isGitlab) {
				// GitLab does not have teams
				delete paramFilterValues.TEAM
			}

			return paramFilterValues
		})

	const availableParameters = useMemo(
		() =>
			scheduleParameters.filter(
				param => !selectedParameters.map(g => g.parameter).includes(param),
			),
		[scheduleParameters, selectedParameters],
	)

	const updateParameterFilterValue = (
		parameter: ScheduleParameter,
		payload: {
			loading?: boolean
			values?: ParameterFilter[]
		},
	) => {
		setParameterFilterValues(prev => ({
			...prev,
			[parameter]: {
				...prev[parameter],
				...payload,
			},
		}))
	}

	const getInstalledRepos = async () => {
		updateParameterFilterValue("REPOSITORY", { loading: true, values: [] })

		await axios
			.get<CRHandlerGetInstalledReposResp>(
				`${import.meta.env.VITE_CODERABBIT_FUNC_URL}/getInstalledRepos`,
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
						"x-coderabbit-organization": selectedOrg?.id,
					},
				},
			)
			.then(({ data }) => {
				const values = data.data.map(repo => ({
					id: repo.repository_id,
					name: repo.repository_name,
				}))
				updateParameterFilterValue("REPOSITORY", { loading: false, values })
			})
			.catch(error => {
				updateParameterFilterValue("REPOSITORY", { loading: false })
				toast.error(
					"Failed to fetch report filter parameter repos: " +
						error?.response?.data?.message || error,
				)
			})
	}

	const getTeams = async () => {
		if (!parameterFilterValues.REPOSITORY?.values[0] || isGitlab) {
			return
		}

		updateParameterFilterValue("TEAM", { loading: true, values: [] })

		await trpcUtils.reporting.getTeams
			.fetch({ repo: parameterFilterValues.REPOSITORY.values[0].name })
			.then(({ data }) => {
				const values = data.teams.map(({ id, name }) => ({
					id: id.toString(),
					name,
				}))
				updateParameterFilterValue("TEAM", { loading: false, values })
			})
			.catch(error => {
				updateParameterFilterValue("TEAM", { loading: false })
				toast.error(
					"Failed to fetch report filter parameter teams: " +
						error?.response?.data?.message || error,
				)
			})
	}

	const getLabels = async () => {
		if (!parameterFilterValues.REPOSITORY?.values.length) {
			return
		}

		updateParameterFilterValue("LABEL", { loading: true, values: [] })
		try {
			const labelPromises = await Promise.allSettled(
				parameterFilterValues.REPOSITORY.values.slice(0, 10).map(repo =>
					trpcUtils.reporting.getLabels
						.fetch({ repo: repo.name })
						.then(({ data }) => {
							return data.labels
						})
						.catch(() => {
							return []
						}),
				),
			)

			const badLabelPromise = labelPromises.find(promiseRejected)
			if (badLabelPromise) {
				toast.error(
					"Failed to fetch report filter parameter labels: " +
						badLabelPromise.reason?.response?.data?.message ||
						badLabelPromise.reason,
				)
			}

			const labels = labelPromises
				.filter(promiseFulfilled)
				.map(promise => promise.value)
				.flat()

			const uniqueLabels = labels.reduce<typeof labels>((unique, label) => {
				return unique.find(item => item.name === label.name)
					? unique
					: [...unique, label]
			}, [])

			const values = uniqueLabels.map(label => ({
				// Uses name instead of ID because multiple repos can have labels with the same name
				// but we want to show them as one single option
				id: label.name,
				name: label.name,
			}))

			updateParameterFilterValue("LABEL", { values, loading: false })
		} catch (error) {
			updateParameterFilterValue("LABEL", { loading: false })
			const err = error as any
			toast.error(
				"Failed to fetch report filter parameter labels: " +
					err?.response?.data?.message || err,
			)
		}
	}

	const getUsers = async () => {
		updateParameterFilterValue("USER", { loading: true, values: [] })

		await trpcUtils.organization_members.getAllMembers
			.fetch()
			.then(seatsInfo => {
				if (seatsInfo.isSuccess) {
					const values = seatsInfo.data.usersData
						.filter(
							user =>
								user.status === "FROM_GITHUB" || user.status === "FROM_GITLAB",
						)
						.map(user => ({
							id: user.user_id.toString(),
							name: user.user_name,
						}))

					updateParameterFilterValue("USER", { loading: false, values })
				} else {
					throw new Error(
						"Failed to fetch report filter parameter users: " +
							seatsInfo.message || "Unknown error",
					)
				}
			})
			.catch(error => {
				updateParameterFilterValue("USER", { loading: false })
				toast.error(
					"Failed to fetch report filter parameter users: " +
						error?.response?.data?.message || error,
				)
			})
	}

	useEffect(() => {
		if (parameterFilterValues.REPOSITORY?.values.length) {
			void getTeams()
			void getLabels()
		}
	}, [parameterFilterValues.REPOSITORY?.values.length])

	useEffect(() => {
		void getInstalledRepos()
		void getUsers()
	}, [])

	return (
		<div>
			<div>
				<div className="font-500 mt-2 font-poppins">Report Parameters</div>
				<div className="max-w-lg font-poppins text-sm text-muted-foreground">
					Fine-tune reports seamlessly. Leverage Report Parameters for accuracy.
				</div>
			</div>

			<div
				className={`mb-2 ${selectedParameters.length ? "mt-4" : "mt-1"} flex flex-col gap-2`}
			>
				{selectedParameters.map(selectedParameter => (
					<div
						key={selectedParameter.parameter}
						className="flex items-center gap-2"
					>
						<div className="flex min-h-12 flex-1 flex-col flex-wrap items-stretch divide-x rounded-lg border bg-white sm:flex-row">
							<select
								className="min-h-10 w-full bg-transparent px-2 sm:w-40"
								name="parameter"
								value={selectedParameter.parameter}
								onChange={event => {
									setSelectedParameters(prev =>
										prev.map(g =>
											g.parameter === selectedParameter.parameter
												? {
														...g,
														parameter: event.target
															.value as typeof selectedParameter.parameter,
														values: [],
													}
												: g,
										),
									)
								}}
							>
								{[selectedParameter.parameter, ...availableParameters].map(
									parameter => (
										<option key={parameter} value={parameter}>
											{capitalizeFirstLetter(parameter.toLowerCase())}
										</option>
									),
								)}
							</select>
							<select
								className="min-h-10 w-full bg-transparent px-2 sm:w-28"
								name="operator"
								value={selectedParameter.operator}
								onChange={event => {
									setSelectedParameters(prev =>
										prev.map(g =>
											g.parameter === selectedParameter.parameter
												? {
														...g,
														operator: event.target
															.value as typeof selectedParameter.operator,
													}
												: g,
										),
									)
								}}
							>
								{parameterFilterValues[
									selectedParameter.parameter
								]?.operators.map(operator => (
									<option key={operator} value={operator}>
										{operator.replace("_", " ")}
									</option>
								))}
							</select>
							<div className="flex flex-1 flex-col flex-wrap gap-x-2 px-2 sm:mt-0 sm:flex-row sm:items-center">
								{selectedParameter.values.length > 0 && (
									<div className="flex flex-wrap gap-2">
										{selectedParameter.values.map(value => {
											const valueName =
												parameterFilterValues[
													selectedParameter.parameter
												]?.values.find(v => v.id === value)?.name ?? value

											return (
												<Chip
													key={value}
													name={valueName}
													onDelete={() => {
														setSelectedParameters(prev =>
															prev.map(g =>
																g.parameter === selectedParameter.parameter
																	? {
																			...g,
																			values: g.values.filter(v => v !== value),
																		}
																	: g,
															),
														)
													}}
												/>
											)
										})}
									</div>
								)}
								{parameterFilterValues[selectedParameter.parameter]?.loading ? (
									<div className="ml-1 flex items-center gap-2 text-gray-500">
										<CgSpinner className="animate-spin text-xl" />
										Loading
									</div>
								) : (
									<Combobox
										comboboxInputProps={{
											style: { border: "none" },
										}}
										outerContainerProps={{
											className: "w-full flex-1 sm:-mt-1 min-w-36",
										}}
										value=""
										placeholder="Select values"
										options={
											parameterFilterValues[
												selectedParameter.parameter
											]?.values.filter(
												value => !selectedParameter.values.includes(value.id),
											) ?? []
										}
										onChange={value => {
											setSelectedParameters(prev =>
												prev.map(g =>
													g.parameter === selectedParameter.parameter
														? {
																...g,
																values: [...g.values, value],
															}
														: g,
												),
											)
										}}
									/>
								)}
							</div>
						</div>
						<button
							className="rounded-lg p-3 text-red-500 hover:bg-red-100"
							title="Delete"
							onClick={() => {
								setSelectedParameters(prev =>
									prev.filter(g => g.parameter !== selectedParameter.parameter),
								)
							}}
						>
							<FaRegTrashAlt />
						</button>
					</div>
				))}

				{selectedParameters.length < MAX_PARAMETERS && (
					<button
						className="mt-3 flex w-fit items-center gap-2 rounded-md border px-3 py-1.5 text-gray-900 hover:bg-gray-100"
						onClick={() => {
							setSelectedParameters(prev => {
								if (!availableParameters[0]) {
									return prev
								}

								return [
									...prev,
									{
										parameter: availableParameters[0],
										operator: ScheduleOperators[0],
										values: [],
									},
								]
							})
						}}
					>
						<IoMdAddCircleOutline className="text-gray-600" size={20} />
						Add Parameter
					</button>
				)}
			</div>
		</div>
	)
}

export default ReportingParameters
