import Loader from "@/components/Loader/Loader"
import DeleteAccountModal from "@/components/Modal/DeleteAccountModal"
import Search from "@/components/Search/Search"
import { Button } from "@/components/ui/button"
import { DataTable } from "@/components/ui/data-table"
import { useColumnFilter } from "@/components/ui/data-table-utils"
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import { updateSeatsList } from "@/services/network/Api.ts"
import { trpc } from "@/trpc"
import type {
	BillingHandlerCheckCustomerResp,
	BillingHandlerCheckoutResp,
	BillingHandlerCreateCustomerBody,
	BillingHandlerCreateCustomerResp,
	BillingHandlerCreatePortalSessionResp,
	BillingHandlerSubscriptionHistoryResp,
	SubscriptionCustomer,
} from "@/typings/billingHandler.ts"
import type {
	CRHandlerCheckOrganizationsResp,
	CRHandlerCreateOrganizationsBody,
	CRHandlerCreateOrganizationsResp,
} from "@/typings/coderabbitHandler.ts"
import { useAllOrgs } from "@/utils/getAllOrgs"
import { useProvider } from "@/utils/providers"
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
import { Box, Modal, Radio } from "@mui/material"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import type { AxiosResponse } from "axios"
import axios from "axios"
import "material-react-toastify/dist/ReactToastify.css"
import React, { useEffect, useMemo, useState } from "react"
import {
	LuBadgeDollarSign,
	LuDollarSign,
	LuTrash2,
	LuUsers2,
} from "react-icons/lu"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import ButtonRegular from "../../../components/ButtonRegular/ButtonRegular"
import ImageButton from "../../../components/ImageButton/ImageButton"
import NavContainer from "../../../components/Nav/NavContainer"
import SeatManagementModal from "../../../components/SeatManagement/SeatManagementModal"
import type { UserData } from "../../../typings"
import {
	capitalizeFirstLetter,
	getSelectedOrg,
	sentryCaptureException,
} from "../../../utils/utils"
import { columns } from "./Columns"
import LimitReachedModal from "./LimitReachedModal"

const PlanNames = [
	{ plan: "FREE", id: "Free" },
	{ plan: "PRO", id: "CRB_MONTHLY_SUBSCRIPTION_PER_SEAT-USD-Monthly" },
	{ plan: "PRO", id: "CRB_ANNUAL_SUBSCRIPTION_PER_SEAT-USD-Yearly" },
	{ plan: "OPEN", id: "CRB_Monthly_OpenSource-USD-Monthly" },
	{ plan: "FREE", id: "GITHUB_MARKETPLACE_FREE-USD-Monthly" },
	{ plan: "OPEN", id: "GITHUB_MARKETPLACE_OPENSOURCE-USD-Monthly" },
	{ plan: "PRO", id: "GITHUB_MARKETPLACE_PRO-USD-Monthly" },
]

const theme = createTheme({
	palette: {
		primary: {
			main: "#FF4702",
		},
	},
})

const SeatFilters = [
	{ key: "All", value: undefined },
	{ key: "Assigned", value: true },
	{ key: "Unassigned", value: false },
] as const

const UserTypeFilters = [
	{ key: "Users", value: "Users" },
	{ key: "Bots", value: "Bots" },
] as const

type SubscriptionHistory = SubscriptionCustomer

// TODO: Replace with types from trpc using RouterOutput
interface SeatsInfo {
	readonly limit: number
	readonly subscriptionId: number
	readonly usersData: UserData[]
	readonly userId?: number
}

function SeatManagement(): React.ReactElement {
	const navigate = useNavigate()
	const { provider, isSelfHosted, isCRSelfHosted } = useProvider()

	const selectedOrg = getSelectedOrg()

	const orgName = selectedOrg?.organization_name
	const userId = sessionStorage.getItem("user_id")
	const userName = sessionStorage.getItem("login")
	const selfHostedDomain = sessionStorage.getItem("selfHostedDomain")

	const [seatsInfo, setSeatsInfo] = useState<SeatsInfo>({
		limit: 0,
		subscriptionId: 0,
		usersData: [],
		userId: 0,
	})

	const [initialState, setInitialState] = useState<SeatsInfo>({
		limit: 0,
		subscriptionId: 0,
		usersData: [],
		userId: 0,
	})

	const [subscriptionHistory, setSubscriptionHistory] =
		useState<SubscriptionHistory>()

	const [currentStatus, setCurrentStatus] = useState<string>("")
	const [proLimitReachedModal, setProLimitReachedModal] =
		useState<boolean>(false)
	const [upgradeSubscriptionModal, setUpgradeSubscriptionModal] =
		useState<boolean>(false)
	const [isSubscriptionError, setIsSubscriptionError] = useState<boolean>(false)
	const [hasLimitReached, setHasLimitedReached] = useState<boolean>(false)
	const [isSaveDisable, setIsSaveDisable] = useState<boolean>(true)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [assignedCount, setAssignedCount] = useState<number>(0)
	const [assignSeat, setAssignSeat] = useState<{
		new: boolean
		gitHub: boolean
	}>({
		new: false,
		gitHub: false,
	})
	const [initialAssignSeat, setInitialAssignSeat] = useState<{
		new: boolean
		gitHub: boolean
	}>({
		new: false,
		gitHub: false,
	})
	const [loaderMessage, setLoaderMessage] = useState<string>("")
	const [modalOpen, setModalOpen] = useState<boolean>(false)
	const { organizations, fetchOrganizations } = useAllOrgs()

	const getSubscriptionHistory = async () => {
		if (sessionStorage.getItem("org_id") != null) {
			await axios
				.get<BillingHandlerSubscriptionHistoryResp>(
					`${
						import.meta.env.VITE_BILLING_FUNC_URL
					}/subscriptionHistory?customer_id=${sessionStorage.getItem("org_id")}`,
					{
						headers: {
							Authorization: `Bearer ${sessionStorage.getItem("auth-token")}`,
						},
					},
				)
				.then(response => {
					const data = response.data
					setIsSubscriptionError(!data.data?.subscription)
					if (data.data?.subscription) {
						setCurrentStatus(data.data.subscription.status)
						setSubscriptionHistory(data.data)
					}
				})
				.catch(error => {
					if (error.response?.status == 401) {
						sessionStorage.clear()
						navigate("/login")
						return
					}
					if (error.response?.status == 404) {
						setIsSubscriptionError(true)
						return
					}
					setIsSubscriptionError(true)
					toast.error(
						"Failed to retrieve subscription history. Please try again.",
					)
				})
		} else {
			sessionStorage.clear()
			navigate("/login")
			return
		}
	}

	const isUserInstanceAdmin = trpc.users.isUserInstanceAdmin.useQuery(
		{
			provider: provider || "",
			user_id: userId || "",
			selfHostedDomain:
				selfHostedDomain && isSelfHosted ? selfHostedDomain : undefined,
		},
		{ enabled: !!(selfHostedDomain && isSelfHosted) },
	)

	const getAllMembers = trpc.organization_members.getAllMembers.useQuery(
		undefined,
		{ enabled: !isCRSelfHosted || organizations.length > 0 },
	)

	useEffect(() => {
		if (getAllMembers.error) {
			if (getAllMembers.error.data?.httpStatus == 404) {
				setSeatsInfo({
					limit: 0,
					subscriptionId: 0,
					usersData: [],
					userId: 0,
				})
				return
			}
			if (getAllMembers.error.data?.httpStatus == 401) {
				toast.error("Session expired. Please login again.")
				sessionStorage.clear()
				navigate("/login")
				return
			}
			toast.error(
				"Failed to fetch the list of members: " + getAllMembers.error.message,
			)
		}
	}, [getAllMembers.error])

	const isAdminOverride = useMemo(
		() =>
			getAllMembers.data?.data.usersData.find(
				user => user.user_id.toString() === sessionStorage.getItem("user_id"),
			)?.override_role === "ADMIN",
		[getAllMembers.data],
	)

	// Super admin is an admin on GitHub/GitLab Self-Hosted or if they have an admin role on the GitHub/GitLab org
	const isSuperAdmin =
		isUserInstanceAdmin.data?.isAdmin || selectedOrg?.role === "admin"
	// Admin is either a super admin or someone who has been granted admin rights by a super admin
	const isAdmin = isSuperAdmin || isAdminOverride

	useEffect(() => {
		if (getAllMembers.data) {
			const seatsInfo = getAllMembers.data
			if (seatsInfo.isSuccess) {
				setSeatsInfo({
					limit: seatsInfo.data.num_seats,
					subscriptionId: seatsInfo.data.subscriptionId,
					usersData: seatsInfo.data.usersData,
				})
				setInitialState({
					limit: seatsInfo.data.num_seats,
					subscriptionId: seatsInfo.data.subscriptionId,
					usersData: seatsInfo.data.usersData,
				})
				setAssignSeat({
					new: seatsInfo.data.joinImmediate,
					gitHub: !seatsInfo.data.joinImmediate,
				})
				setInitialAssignSeat({
					new: seatsInfo.data.joinImmediate,
					gitHub: !seatsInfo.data.joinImmediate,
				})
			} else {
				setSeatsInfo({
					limit: 0,
					subscriptionId: 0,
					usersData: [],
					userId: 0,
				})
			}
		}
	}, [getAllMembers.data])

	const checkOrg = async () => {
		const selfHostedDomain = sessionStorage.getItem("selfHostedDomain")

		let baseURL = `${
			import.meta.env.VITE_CODERABBIT_FUNC_URL
		}/checkOrganizations?provider_organization_id=${selectedOrg?.provider_organization_id}&provider=${selectedOrg?.provider}`

		if (selfHostedDomain) {
			baseURL += `&selfHostedDomain=${selfHostedDomain}`
		}
		const result = await axios
			.get<CRHandlerCheckOrganizationsResp>(baseURL, {
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("auth-token")}`,
				},
			})
			.then(response => {
				if (response.status === 404) {
					return false
				}
				sessionStorage.setItem("org_id", response.data.data.id)
				return true
			})
			.catch(error => {
				console.error(error)
				sentryCaptureException(
					"checkOrg: checkOrganizations API failed: ",
					error,
				)
				return false
			})
		return result
	}

	const createOrg = async () => {
		if (!selectedOrg) {
			return
		}

		const userId = sessionStorage.getItem("user_id")
		const body: CRHandlerCreateOrganizationsBody = {
			organization_name: selectedOrg.organization_name,
			provider_organization_id: selectedOrg.provider_organization_id,
			provider: selectedOrg.provider,
			provider_user_id: userId,
			memberCount: selectedOrg.memberCount,
		}

		if (isSelfHosted) {
			body.selfHostedDomain = sessionStorage.getItem("selfHostedDomain")
		}

		await axios
			.post<CRHandlerCreateOrganizationsResp>(
				`${import.meta.env.VITE_CODERABBIT_FUNC_URL}/createOrganizations`,
				body,
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem("auth-token")}`,
					},
				},
			)
			.then(res => {
				sessionStorage.setItem("org_id", res.data.data.id)
			})
			.catch(error => {
				toast.error(error.response.data.message)
				sentryCaptureException(
					"createOrg: createOrganizations API failed: ",
					error,
				)
			})
	}

	async function checkCustomer() {
		const result = await axios
			.get<BillingHandlerCheckCustomerResp>(
				`${
					import.meta.env.VITE_BILLING_FUNC_URL
				}/checkCustomer?customer_id=${sessionStorage.getItem("org_id")}`,
			)
			.then(response => {
				if (response.status === 500) {
					return false
				}
				return true
			})
			.catch(error => {
				console.error(error)
				if (error.response?.status != 404) {
					sentryCaptureException(
						"checkCustomer: checkCustomer API failed: ",
						error,
					)
				}
				return false
			})

		return result
	}

	async function createCustomer() {
		const myHeaders = {
			"Content-Type": "application/json",
		}

		const body = {
			user: {
				id: sessionStorage.getItem("org_id")!,
				first_name: selectedOrg?.organization_name || "",
				last_name: "",
				email: "",
				provider: provider ?? "",
				host_url: sessionStorage.getItem("selfHostedDomain")!,
			},
		}

		return axios
			.post<
				BillingHandlerCreateCustomerResp,
				AxiosResponse<BillingHandlerCreateCustomerResp>,
				BillingHandlerCreateCustomerBody
			>(`${import.meta.env.VITE_BILLING_FUNC_URL}/createCustomer`, body, {
				headers: myHeaders,
			})
			.then(() => true)
			.catch(error => {
				console.error(error)
				sentryCaptureException(
					"createCustomer: createCustomer API failed: ",
					error,
				)
				return false
			})
	}

	const toggleAdmin = trpc.users.toggleAdmin.useMutation({
		onSuccess: ({ data, message }) => {
			if (!data || !("role" in data)) {
				toast.error("Failed to change user role: " + message)
				return
			}

			const isAdminNow = data.role === "ADMIN"

			getAllMembers.remove()
			void getAllMembers.refetch().finally(() => {
				toast.success(
					isAdminNow
						? "Promoted user to admin successfully"
						: "Revoked admin access successfully",
				)
			})
		},
		onError: err => {
			toast.error("Failed to change user role: " + err.message)
		},
	})

	async function checkOut() {
		const itemId = "CRB_MONTHLY_SUBSCRIPTION_PER_SEAT-USD-Monthly"
		const quantity = 1

		const orgExists = await checkOrg()
		if (!orgExists) await createOrg()

		const customerExists = await checkCustomer()
		if (!customerExists) await createCustomer()

		const userId = sessionStorage.getItem("org_id")

		if (!isAdmin) {
			toast.error(
				"Action denied: Only organization admins can buy subscriptions.",
			)
			return
		}

		await axios
			.get<BillingHandlerCheckoutResp>(
				`${
					import.meta.env.VITE_BILLING_FUNC_URL
				}/checkout?item_id=${itemId}&customer_id=${userId}&quantity=${quantity}`,
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem("auth-token")}`,
					},
				},
			)
			.then(response => {
				const data = response.data
				if (data.data.url) {
					window.location.assign(data.data.url)
				}
			})
			.catch(error => {
				if (error.response?.status == 409) {
					toast.error(error.response.data.message)
				}
				if (error.response?.status == 401) {
					sessionStorage.clear()
					navigate("/login")
					return
				}
				if (error.code == "ERR_BAD_RESPONSE") {
					toast.error(
						error.response.data.data?.message.substring(
							15,
							error.response.data.data.message.length - 1,
						),
					)
				}
				toast.error("Failed to initiate checkout. Please try again.")
				sentryCaptureException("checkOut: API failed: ", error)
			})
	}

	const marketplaceItem =
		subscriptionHistory?.subscription.subscription_items?.find(item =>
			item.item_price_id.includes("GITHUB_MARKET"),
		)
	const isMarketPlacePlan = !!marketplaceItem

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true)
			setLoaderMessage("Loading your subscription details... 🚀")
			try {
				if (isCRSelfHosted) {
					await fetchOrganizations()
				}
				await getSubscriptionHistory()
			} catch (error) {
				// Handle errors appropriately
				sentryCaptureException(
					"fetchData: API failed on seat management page: ",
					error,
				)
			} finally {
				setIsLoading(false)
			}
		}

		void fetchData()
	}, [])

	useEffect(() => {
		// Compare seatsInfo and initialState
		const isSeatsInfoDifferent =
			JSON.stringify(seatsInfo) !== JSON.stringify(initialState)

		// Compare assignSeat with its initial value
		const isAssignSeatDifferent =
			JSON.stringify(assignSeat) !== JSON.stringify(initialAssignSeat)

		// Update isSaveDisabled based on the comparison results
		setIsSaveDisable(!isSeatsInfoDifferent && !isAssignSeatDifferent)
	}, [seatsInfo, initialState, assignSeat])

	const findUpdatedObjects = (arrayA: UserData[], arrayB: UserData[]) => {
		const changedObj = []
		for (const objA of arrayA) {
			const objB = arrayB.find(obj => obj.user_id == objA.user_id)
			if (objB && objA.on_seat !== objB.on_seat) {
				changedObj.push(objB)
			}
		}
		return changedObj
	}

	const handleLimitUpdateConfirm = async () => {
		setHasLimitedReached(false)
		const updatedUsersList = findUpdatedObjects(
			initialState.usersData,
			seatsInfo.usersData,
		)
		const response = await updateSeatsList({
			subscriptionId: seatsInfo.subscriptionId,
			userSeats: updatedUsersList,
			joinImmediate: assignSeat.new ? true : false,
			provider: provider || "",
			loggedInDetails: {
				org_name: orgName || "",
				user_id: userId,
				user_name: userName,
			},
		})

		if (response.kind === "ok") {
			toast("Changes Saved Successfully!!", {
				position: "top-right",
				autoClose: 2500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "colored",
				type: "success",
			})
		} else {
			if (response.status == 401 || response.kind === "error") {
				sessionStorage.clear()
				navigate("/login")
				return
			}

			toast("Something went wrong, Please try again", {
				position: "top-right",
				autoClose: 2500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "colored",
				type: "error",
			})
		}

		void getAllMembers.refetch()
	}

	const handleLimitUpdateCancel = () => {
		setHasLimitedReached(false)
	}

	const checkLimit = () => {
		let count = 0
		seatsInfo.usersData.forEach(user => {
			if (user.on_seat) {
				count++
			}
		})
		return count <= seatsInfo.limit
	}

	const handleSaveChanges = () => {
		setIsSaveDisable(true)
		if (checkLimit()) {
			void handleLimitUpdateConfirm()
		} else {
			setHasLimitedReached(true)
		}
	}

	const handleAssignSeat = (value: "gitHub" | "new") => {
		setAssignSeat({ new: value === "new", gitHub: value === "gitHub" })
	}

	const handleToggleStatus = (row: UserData) => {
		const data = seatsInfo.usersData.map(item => {
			if (item.user_id === row.user_id) {
				return {
					...item,
					on_seat: !row.on_seat,
				}
			} else {
				return item
			}
		})
		let count = 0
		data.forEach(user => {
			if (user.on_seat) {
				count++
			}
		})
		if (count > seatsInfo.limit && isMarketplaceProPlan) {
			setProLimitReachedModal(true)
			return
		}
		setSeatsInfo({
			...seatsInfo,
			usersData: data,
		})
		setAssignedCount(count)
	}

	const convertDate = (timestamp: number) => {
		const date = new Date(timestamp * 1000)
		return date.toLocaleDateString(undefined, {
			year: "numeric",
			month: "short",
			day: "numeric",
		})
	}

	const getStatusLabel = () => {
		switch (subscriptionHistory?.subscription.status) {
			case "in_trial":
				return "Trial"
			case "active":
			case "cancelled":
				return "Active"
			case "non_renewing":
				return "Not Renewing"
			default:
				return ""
		}
	}

	const redirectChargebeePortal = async () => {
		await axios
			.get<BillingHandlerCreatePortalSessionResp>(
				`${
					import.meta.env.VITE_BILLING_FUNC_URL
				}/createPortalSession?customer_id=${sessionStorage.getItem("org_id")}`,
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem("auth-token")}`,
					},
				},
			)
			.then(response => {
				const data = response.data
				window.location.assign(data.data.access_url)
			})
			.catch(error => {
				if (error.response?.status == 401) {
					sessionStorage.clear()
					navigate("/login")
					return
				}
				toast("Something went wrong!!", {
					position: "top-right",
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "colored",
					type: "error",
				})
				console.log(error)
				sentryCaptureException("redirectChargebeePortal: API failed: ", error)
			})
	}

	function navigateToMarketPlce() {
		const MARKETPLACE_APP_NAME =
			import.meta.env.VITE_MARKETPLACE_APP_NAME || "coderabbit-reviewer-dev"
		window.location.assign(
			`https://github.com/marketplace/${MARKETPLACE_APP_NAME}`,
		)
	}

	const manageBilling = async (
		subscription: SubscriptionHistory["subscription"] | undefined,
	) => {
		const isMarketPlacePlan = (subscription?.subscription_items ?? []).find(
			item => item.item_price_id.includes("GITHUB_MARKET"),
		)
		if (isMarketPlacePlan) {
			navigateToMarketPlce()
		} else {
			if (subscriptionHistory?.subscription.status === "cancelled") {
				setUpgradeSubscriptionModal(true)
			} else {
				await redirectChargebeePortal()
			}
		}
	}

	const checkPlan =
		subscriptionHistory?.subscription.status === "cancelled"
			? PlanNames[0]?.plan
			: PlanNames.map(plan => {
					const pId =
						subscriptionHistory?.subscription.subscription_items?.[0]
							?.item_price_id
					return plan.id == pId && plan.plan
				}).find(item => typeof item === "string")

	const checkFreePlan =
		subscriptionHistory?.subscription.subscription_items?.[0]?.item_price_id ==
			"GITHUB_MARKETPLACE_FREE-USD-Monthly" ||
		subscriptionHistory?.subscription.subscription_items?.[0]?.item_price_id ==
			"GITHUB_MARKETPLACE_OPENSOURCE-USD-Monthly"

	const isMarketplaceProPlan =
		subscriptionHistory?.subscription.subscription_items?.[0]?.item_price_id ==
		"GITHUB_MARKET_PLACE_PRO-USD-Monthly"

	const disableRadioButton = () => {
		return (
			!isAdmin ||
			isMarketPlacePlan ||
			checkPlan === "FREE" ||
			checkFreePlan ||
			currentStatus === "in_trial"
		)
	}

	const disableRadioButtonSeatAssignment = () => {
		return !isAdmin || checkPlan === "FREE" || checkFreePlan
	}

	const assignedSeatToNewDev = () => {
		if (
			assignSeat.new ||
			checkPlan === "FREE" ||
			checkFreePlan ||
			currentStatus === "in_trial"
		) {
			return true
		} else return false
	}

	const assignSeatToGitHub = () =>
		currentStatus !== "in_trial" && checkPlan !== "FREE" && assignSeat.gitHub

	const getRenewalDate = () => {
		// I have no idea why the logic for get renewal date is like this
		if (subscriptionHistory?.subscription.status === "cancelled") {
			return convertDate(subscriptionHistory.subscription.cancelled_at || 0)
		} else if (
			subscriptionHistory?.subscription.status === "in_trial" &&
			subscriptionHistory.subscription.cancelled_at
		) {
			return convertDate(subscriptionHistory.subscription.cancelled_at)
		} else if (subscriptionHistory?.subscription.status === "in_trial") {
			return convertDate(subscriptionHistory.subscription.next_billing_at || 0)
		} else {
			return convertDate(
				subscriptionHistory?.subscription.current_term_end || 0,
			)
		}
	}

	const assignSeatToolTipMessage = !isAdmin
		? "You need admin rights to change seat settings"
		: checkPlan == "FREE" || checkFreePlan
			? "You can't change this option in free plan"
			: currentStatus == "in_trial"
				? "You can't change this option in trial period"
				: ""

	const tableDisableToolTipMessage = !isAdmin
		? "You need admin rights to change seat status"
		: checkPlan == "FREE" || checkFreePlan
			? "You can't change this option in free plan"
			: ""

	const manageSubscriptionTooltip = !isAdmin
		? `You need admin rights`
		: subscriptionHistory?.subscription.subscription_items?.[0]?.item_price_id.includes(
					"GITHUB_MARKETPLACE",
			  )
			? "Go to GitHub marketplace to manage your subscription."
			: null

	// Count total assigned seats and users
	const developerSeats = seatsInfo.usersData.reduce(
		(acc, curr) => ({
			onSeatTrue: acc.onSeatTrue + (curr.on_seat ? 1 : 0),
			total: acc.total + 1,
		}),
		{ onSeatTrue: 0, total: 0 },
	)

	const { getProviderIcon, isGithub } = useProvider()

	const { columnFilters, setColumnFilters, setColumnFilter } = useColumnFilter<
		(typeof seatsInfo.usersData)[number]
	>([
		{
			id: "is_bot",
			value: false,
		},
	])

	return (
		<NavContainer>
			{isLoading || getAllMembers.isLoading || toggleAdmin.isLoading ? (
				<Loader message={loaderMessage} />
			) : (
				<div className="container relative mx-auto px-8 pb-2 pt-7">
					{isSubscriptionError ? (
						<div className="w-full">
							<div className="mb-8 mt-8 flex flex-col items-center justify-center gap-8 rounded-2xl border bg-white p-6 shadow-md">
								<span className="font-inter text-sm font-normal not-italic leading-5">
									Get started now for free, no credit card required! Just choose
									a monthly or annual plan below, select the number of seats you
									need and click 'Subscribe'. You can adjust or cancel your
									subscription anytime. Ready to get started?
								</span>
								<ImageButton title="Start Free Trial" onClick={checkOut} />
							</div>
						</div>
					) : null}

					<div>
						{!isSubscriptionError && (
							<div className="flex items-center justify-between sm:flex-row">
								<span className="font-inter text-xl font-semibold text-black">
									Subscription
								</span>
								{!disableRadioButtonSeatAssignment() && (
									<ButtonRegular
										title="Save"
										disabled={isSaveDisable}
										onClick={handleSaveChanges}
									/>
								)}
							</div>
						)}

						{!isSubscriptionError && (
							<div className="my-2 flex flex-col gap-4 md:flex-row">
								<div className="flex flex-1 flex-col gap-2 rounded-md border p-5">
									<div className="mb-4 flex flex-wrap justify-between gap-4">
										<div className="flex items-center gap-3 pb-10">
											{getProviderIcon({ size: 46 })}
											<div className="flex flex-col">
												<div className="font-500 flex items-center gap-2">
													{subscriptionHistory?.customer.company ||
														selectedOrg?.organization_name}
													<Tooltip>
														<TooltipTrigger>
															<div className="font-600 h-fit rounded-full bg-secondary px-2 py-1 text-sm">
																{checkPlan}
															</div>
														</TooltipTrigger>
														{subscriptionHistory?.subscription.started_at && (
															<TooltipContent>
																Since{" "}
																{convertDate(
																	subscriptionHistory.subscription.started_at,
																)}
															</TooltipContent>
														)}
													</Tooltip>
												</div>

												<div className="-mt-0.5 text-muted-foreground">
													{getStatusLabel()}
												</div>
											</div>
										</div>

										<div className="flex flex-col gap-2">
											<Tooltip>
												<TooltipTrigger asChild>
													<div>
														<Button
															variant="outline"
															onClick={() => {
																void manageBilling(
																	subscriptionHistory?.subscription,
																)
															}}
															disabled={!isAdmin}
														>
															<LuBadgeDollarSign className="mr-2" size={20} />
															{subscriptionHistory?.subscription.status !=
															"cancelled"
																? "Manage Subscription"
																: "Upgrade Subscription"}
														</Button>
													</div>
												</TooltipTrigger>
												{manageSubscriptionTooltip && (
													<TooltipContent>
														{manageSubscriptionTooltip}
													</TooltipContent>
												)}
											</Tooltip>
											{isSuperAdmin && (
												<Button
													className="py-0"
													variant="destructive"
													onClick={() => {
														setModalOpen(true)
													}}
												>
													<LuTrash2 className="mr-2" size={20} />
													Delete Account
												</Button>
											)}
										</div>
									</div>

									{seatsInfo.limit >= 1 && (
										<div className="flex items-center gap-3 text-sm">
											<LuUsers2 size={16} />
											{`${developerSeats.onSeatTrue}/${seatsInfo.limit} ${
												seatsInfo.limit === 1 ? "seat" : "seats"
											} assigned`}
										</div>
									)}
									{subscriptionHistory?.subscription.status != "cancelled" && (
										<div className="flex items-center gap-3 text-sm">
											<LuDollarSign size={16} />
											Renewal on {getRenewalDate()}
										</div>
									)}
								</div>
								<div className="flex flex-1 flex-col justify-stretch gap-2 rounded-md border p-5 lg:flex-initial">
									<div className="font-500 mb-2">Seat Assignment</div>
									<Tooltip>
										<TooltipTrigger>
											<div className="flex flex-row items-center">
												<ThemeProvider theme={theme}>
													<Radio
														checked={assignedSeatToNewDev()}
														onClick={() => {
															handleAssignSeat("new")
														}}
														disabled={disableRadioButton()}
													/>
												</ThemeProvider>
												<span className="flex items-center gap-1 text-left font-poppins text-sm font-normal not-italic leading-5">
													Automatically add seats for new developers
												</span>
											</div>
											<div className="flex flex-row items-center">
												<ThemeProvider theme={theme}>
													<Radio
														checked={assignSeatToGitHub()}
														onClick={() => {
															handleAssignSeat("gitHub")
														}}
														disabled={disableRadioButton()}
													/>
												</ThemeProvider>
												<span className="text-left font-poppins text-sm font-normal not-italic leading-5">
													Manually add and assign seats (Default)
												</span>
											</div>
										</TooltipTrigger>
										{assignSeatToolTipMessage && (
											<TooltipContent side="bottom" sideOffset={16}>
												{assignSeatToolTipMessage}
											</TooltipContent>
										)}
									</Tooltip>
								</div>
							</div>
						)}

						<div>
							<div className="mb-4 mt-8 flex flex-col justify-between gap-4 sm:flex-row sm:items-center">
								<Search
									onSearch={query => {
										setColumnFilter("user_name", query)
									}}
									showLabel={false}
								/>
								<Tabs
									defaultValue={SeatFilters[0].key}
									onValueChange={key => {
										setColumnFilter(
											"on_seat",
											SeatFilters.find(seatFilter => seatFilter.key === key)
												?.value ?? undefined,
										)
									}}
								>
									<TabsList>
										{SeatFilters.map(({ key }) => (
											<TabsTrigger key={key} value={key}>
												{capitalizeFirstLetter(key.toLowerCase())}
											</TabsTrigger>
										))}
									</TabsList>
								</Tabs>
							</div>

							{isGithub && (
								<Tabs
									className="mb-4"
									defaultValue={UserTypeFilters[0].key}
									onValueChange={key => {
										setColumnFilter(
											"is_bot",
											UserTypeFilters.find(
												userTypeFilter => userTypeFilter.key === key,
											)?.value === "Bots",
										)
									}}
								>
									<TabsList>
										{UserTypeFilters.map(({ key }) => (
											<TabsTrigger key={key} value={key}>
												<Tooltip>
													<TooltipTrigger asChild>
														<div>
															{capitalizeFirstLetter(key.toLowerCase())}
														</div>
													</TooltipTrigger>
													{key === "Bots" && (
														<TooltipContent
															side="right"
															sideOffset={25}
															align="center"
															className="font-400"
														>
															Bots will appear in the list once they open a PR
														</TooltipContent>
													)}
												</Tooltip>
											</TabsTrigger>
										))}
									</TabsList>
								</Tabs>
							)}

							<DataTable
								data={seatsInfo.usersData}
								columns={columns({
									onSeatAssignmentChange: userData => {
										handleToggleStatus(userData)
									},
									onSeatAssignmentClick: () => {
										setModalOpen(disableRadioButtonSeatAssignment())
									},
									seatAssignmentDisabled: disableRadioButtonSeatAssignment(),
									seatDisableTooltip: tableDisableToolTipMessage,
									isNewAssignSeat: assignSeat.new,
									isTrial: currentStatus === "in_trial",
									onOverrideRole: userData => {
										toggleAdmin.mutate({
											user_id: userData.user_id.toString(),
											on_seat: userData.on_seat,
										})
									},
								})}
								tableOptions={{
									initialState: {
										columnVisibility: {
											is_bot: false,
										},
									},
									onColumnFiltersChange: setColumnFilters,
									state: {
										columnFilters,
									},
								}}
							/>
						</div>

						{modalOpen && (
							<DeleteAccountModal
								open={modalOpen}
								onOpenChange={setModalOpen}
								onDeleteOrg={() => {
									sessionStorage.clear()
									navigate("/login")
								}}
							/>
						)}

						<Modal
							open={hasLimitReached}
							onClose={() => {
								setHasLimitedReached(false)
							}}
						>
							<Box
								sx={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									padding: 24,
									background: "white",
									borderRadius: 8,
									border: "1px solid white",
									boxShadow: 24,
									p: 4,
								}}
							>
								<div className="flex flex-col gap-4">
									<span className="font-poppins text-xl  font-semibold leading-7">
										Save Changes
									</span>
									<span className="font-poppins">
										You are adding {assignedCount - seatsInfo.limit} more seats
										to your organization. This will bring the total seats to{" "}
										{assignedCount}. Are you sure you want to save the changes
										done?
									</span>

									<div className="flex flex-row justify-end gap-3">
										<button
											className="flex items-center justify-center gap-2 self-stretch rounded-2xl bg-crb-primary p-2 px-5 text-white"
											onClick={handleLimitUpdateConfirm}
										>
											<SaveOutlinedIcon fontSize="small" />
											<span className="text-center font-poppins text-sm font-medium not-italic leading-5">
												Save
											</span>
										</button>
										<button
											className="rounded-[20px] border border-solid border-[#D1D1D1] px-4 py-2 font-poppins"
											onClick={handleLimitUpdateCancel}
										>
											Cancel
										</button>
									</div>
								</div>
							</Box>
						</Modal>

						<LimitReachedModal
							open={proLimitReachedModal}
							setOpen={setProLimitReachedModal}
						/>
						<SeatManagementModal
							isOpen={upgradeSubscriptionModal}
							setIsOpen={setUpgradeSubscriptionModal}
							plan={subscriptionHistory?.subscription.billing_period_unit}
							subscriptionID={subscriptionHistory?.subscription.id}
							quantity={
								subscriptionHistory?.subscription.subscription_items?.[0]
									?.quantity
							}
						/>
					</div>
				</div>
			)}
		</NavContainer>
	)
}
export default SeatManagement
