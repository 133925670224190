import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

interface ProtectedRoutesProps {
	readonly Component: React.ElementType
}

const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({ Component }) => {
	const navigate = useNavigate()

	const checkLogin = () => {
		const login = sessionStorage.getItem("accessToken")

		if (login == null) {
			navigate("/login")
		}
	}

	useEffect(() => {
		checkLogin()
	}, [])

	return <Component />
}

export default ProtectedRoutes
