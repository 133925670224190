import type {
	CRHandlerMailSubscriptionBody,
	CRHandlerMailSubscriptionResp,
	CRHandlerUpdateMergeFieldsBody,
	CRHandlerUpdateMergeFieldsResp,
} from "@/typings/coderabbitHandler.ts"
import { getCookie, sentryCaptureException } from "@/utils/utils.ts"
import type { AxiosResponse } from "axios"
import axios from "axios"
import type { Dispatch, SetStateAction } from "react"
const SIGN_UP_MOBILE = "Sign Up Later (Mobile)"

export async function handleEmailSubmit(
	email: string,
	isValidEmail: boolean,
	setShowEmailInput: Dispatch<SetStateAction<boolean>>,
	setIsSubmitted: Dispatch<SetStateAction<boolean>>,
	setShowCheckmark: Dispatch<SetStateAction<boolean>>,
) {
	setIsSubmitted(true)

	if (isValidEmail) {
		setShowEmailInput(false)
		const tags = [SIGN_UP_MOBILE]
		const mergeField = generateMergeFields(true)

		await axios
			.post<
				CRHandlerMailSubscriptionResp,
				AxiosResponse<CRHandlerMailSubscriptionResp>,
				CRHandlerMailSubscriptionBody
			>(`${import.meta.env.VITE_CODERABBIT_FUNC_URL}/mail_subscription`, {
				email_address: email,
				status: "subscribed",
				tags: tags,
				merge_fields: mergeField,
			})
			.then(response => {
				if (response.status === 200) {
					setShowCheckmark(true)
				} else {
					// Handle other status codes if needed
					setShowEmailInput(true)
				}
			})
			.catch(error => {
				// Handle the error
				console.error("Error submitting email:", error)
				setShowEmailInput(true)
			})
	} else {
		setShowEmailInput(true)
	}
}

export async function updateMailChimpUtm() {
	const mergeField = generateMergeFields(true)
	if (Object.keys(mergeField).length === 0) {
		return
	}

	try {
		const user_id = sessionStorage.getItem("user_id")
		if (!user_id) return

		const provider =
			sessionStorage.getItem("provider")?.replace(/"/g, "") ?? "github"
		const selfHostedDomain = sessionStorage.getItem("selfHostedDomain")

		const apiURL = new URL(
			"updateMergeFields",
			`${import.meta.env.VITE_CODERABBIT_FUNC_URL}/`,
		)
		if (selfHostedDomain)
			apiURL.searchParams.set("selfHostedDomain", selfHostedDomain)

		await axios.post<
			CRHandlerUpdateMergeFieldsResp,
			AxiosResponse<CRHandlerUpdateMergeFieldsResp>,
			CRHandlerUpdateMergeFieldsBody
		>(apiURL.toString(), {
			user_id: user_id,
			merge_fields: mergeField,
			provider,
		})
	} catch (err) {
		sentryCaptureException("updateCustomer: API failed: ", err)
	}
}

export function generateMergeFields(mailchimp: boolean) {
	const mergeField: Record<string, string> = {}
	const cookies = {
		gclid: getCookie("gclid"),
		msclkid: getCookie("msclkid"),
		fbclid: getCookie("fbclid"),
		twclid: getCookie("twclid"),
		utm_source: getCookie("utm_source"),
		utm_medium: getCookie("utm_medium"),
		utm_campaign: getCookie("utm_campaign"),
		utm_term: getCookie("utm_term"),
		utm_content: getCookie("utm_content"),
		hutk: getCookie("hubspotutk"),
	}

	const mailchimpParameters = {
		GCLID_1: cookies.gclid,
		MSCLKID: cookies.msclkid,
		FBCLID: cookies.fbclid,
		TWCLID: cookies.twclid,
		UTM_CAMP: cookies.utm_campaign,
		UTM_SRC: cookies.utm_source,
		UTM_MED: cookies.utm_medium,
		UTM_TERM: cookies.utm_term,
		UTM_CONT: cookies.utm_content,
	}

	const defaultParameters = {
		gclid: cookies.gclid,
		msclkid: cookies.msclkid,
		fbclid: cookies.fbclid,
		twclid: cookies.twclid,
		utm_campaign: cookies.utm_campaign,
		utm_source: cookies.utm_source,
		utm_medium: cookies.utm_medium,
		utm_term: cookies.utm_term,
		utm_content: cookies.utm_content,
		hutk: cookies.hutk,
	}

	const parameters = mailchimp ? mailchimpParameters : defaultParameters

	for (const [key, value] of Object.entries(parameters)) {
		if (value !== null) {
			mergeField[key] = value
		}
	}

	return mergeField
}
