import type { OrganizationData } from "@/typings"
import { useAllOrgs } from "@/utils/getAllOrgs"
import { getSelectedOrg } from "@/utils/utils.ts"
import React, { useEffect, useState } from "react"
import { IoMdRefresh } from "react-icons/io"

interface DesktopSelectOrgProps {
	readonly selectedOrgName: string | undefined
}

const DesktopSelectOrg: React.FC<DesktopSelectOrgProps> = ({
	selectedOrgName,
}) => {
	const [options, setOptions] = useState<OrganizationData[]>([])
	const [refreshing, setRefreshing] = useState<boolean>(false)

	const { fetchOrganizations } = useAllOrgs()

	useEffect(() => {
		let orgData = JSON.parse(sessionStorage.getItem("org_info")!) as
			| OrganizationData[]
			| null
		orgData = (orgData ?? []).filter(item => !item.parentId)
		setOptions(orgData)

		const selectedOrg = getSelectedOrg()
		if (selectedOrg) {
			setValue(selectedOrg.organization_name)
		}
	}, [refreshing])

	useEffect(() => {
		const selectedOrg = getSelectedOrg()
		if (selectedOrg) {
			setValue(selectedOrg.organization_name)
		}
	}, [selectedOrgName])

	const [value, setValue] = useState("select")

	const handleRefresh = async () => {
		setRefreshing(true)
		await fetchOrganizations(true)
		setRefreshing(false)
	}

	const onChange = (event: React.MouseEvent<HTMLLIElement>) => {
		sessionStorage.setItem(
			"org_id",
			event.currentTarget.getAttribute("data-value")!,
		)
		sessionStorage.setItem(
			"selected_org",
			event.currentTarget.getAttribute("data-value")!,
		)
		sessionStorage.setItem(
			"selected_org",
			event.currentTarget.getAttribute("data-org")!,
		)

		if (
			window.location.href.includes("installation-success") ||
			window.location.href.includes("settings")
		) {
			window.location.assign("/dashboard")
		} else {
			window.location.reload()
		}
	}
	return (
		<div className="py-2">
			<div className="px-4">
				<div className="flex items-center gap-1.5">
					<h1 className="text-base">Organizations</h1>
					<IoMdRefresh
						className={`text-gray-400 hover:cursor-pointer hover:text-gray-500 ${refreshing ? "animate-spin" : ""}`}
						size={18}
						onClick={handleRefresh}
					/>
				</div>
				<p
					className={`text-xs ${!refreshing ? "text-success" : "animate-pulse text-gray-400"}`}
				>
					{refreshing ? "Refreshing..." : "Synced"}
				</p>
			</div>

			<hr className="my-2.5 border-gray-200" />

			<ul
				className="max-h-44 overflow-y-auto text-sm scrollbar-thin"
				aria-labelledby="dropdownLargeButton"
			>
				{options.map(option => (
					<li
						className="block px-4 py-2 hover:cursor-pointer hover:bg-gray-100"
						onClick={onChange}
						key={option.id}
						data-org={JSON.stringify(option)}
						data-value={option.id}
						data-org-id={option.id}
					>
						{option.organization_name}
						{value == option.organization_name && (
							<span className="ml-2 rounded-full bg-crb-primary px-2.5 py-0.5 text-xs font-medium text-white">
								Current
							</span>
						)}
					</li>
				))}
			</ul>
		</div>
	)
}

export default DesktopSelectOrg
