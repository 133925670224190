import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table"

import type {
	ColumnDef,
	ColumnFiltersState,
	SortingState,
	TableOptions,
} from "@tanstack/react-table"

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import { cn } from "@/lib/utils"
import { CircularProgress } from "@mui/material"
import { omit } from "lodash"
import React from "react"
import { DataTablePagination } from "./data-table-pagination"

interface DataTableProps<TData, TValue> {
	columns: ColumnDef<TData, TValue>[]
	data: TData[]
	tableOptions?: Partial<TableOptions<TData>>
	hideTableHeader?: boolean
	tableWrapperProps?: React.HTMLAttributes<HTMLDivElement>
	tableRowProps?: React.HTMLAttributes<HTMLTableRowElement>
	tableCellProps?: React.TdHTMLAttributes<HTMLTableCellElement>
	isLoading?: boolean
}

export function DataTable<TData, TValue>({
	columns,
	data,
	tableOptions,
	hideTableHeader,
	tableWrapperProps,
	tableRowProps,
	tableCellProps,
	isLoading = false,
}: DataTableProps<TData, TValue>) {
	const [sorting, setSorting] = React.useState<SortingState>([])
	const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
		[],
	)

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel<TData>(),
		getPaginationRowModel: getPaginationRowModel<TData>(),
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel<TData>(),
		onColumnFiltersChange: setColumnFilters,
		getFilteredRowModel: getFilteredRowModel<TData>(),
		...tableOptions,
		state: {
			sorting,
			columnFilters,
			...tableOptions?.state,
		},
	})

	return (
		<div>
			{isLoading ? (
				<div className="flex min-h-80 w-full flex-col items-center justify-center">
					<CircularProgress
						style={{ color: /* crb-primary */ "#FF4702", marginTop: "10px" }}
						size={40}
					/>
				</div>
			) : (
				<div
					className={cn("rounded-md border", tableWrapperProps?.className)}
					{...omit(tableWrapperProps, "className")}
				>
					<Table>
						{!hideTableHeader && (
							<TableHeader>
								{table.getHeaderGroups().map(headerGroup => (
									<TableRow key={headerGroup.id} className="w-full">
										{headerGroup.headers.map(header => (
											<TableHead
												key={header.id}
												className="px-4 text-start font-inter text-xs font-medium uppercase tracking-wider"
											>
												{header.isPlaceholder
													? null
													: flexRender(
															header.column.columnDef.header,
															header.getContext(),
														)}
											</TableHead>
										))}
									</TableRow>
								))}
							</TableHeader>
						)}
						<TableBody>
							{table.getRowModel().rows.length ? (
								table.getRowModel().rows.map(row => (
									<TableRow
										key={row.id}
										data-state={row.getIsSelected() && "selected"}
										className={cn(
											(row.original as { disabled?: boolean }).disabled
												? "group/disabled bg-gray-100"
												: "",
											tableRowProps?.className,
										)}
										{...omit(tableRowProps, "className")}
									>
										{row.getVisibleCells().map(cell => (
											<TableCell
												key={cell.id}
												className={cn(
													"font-500 whitespace-nowrap py-2 text-sm text-foreground",
													tableCellProps?.className,
												)}
												{...omit(tableCellProps, "className")}
											>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext(),
												)}
											</TableCell>
										))}
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell
										colSpan={columns.length}
										className="p-6 text-center"
									>
										No results
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</div>
			)}
			<div className="my-4">
				<DataTablePagination table={table} />
			</div>
		</div>
	)
}
