export const API_RESPONSE_TYPE = "json"
export const API_OK = "ok"

export const getPathConfig = () => {
	const githubHandler = import.meta.env.VITE_GITHUB_FUNC_URL
	const codeRabbitHandler = import.meta.env.VITE_CODERABBIT_FUNC_URL
	// const billingHandler = import.meta.env.VITE_BILLING_FUNC_URL

	return {
		GET_SEATS: `${githubHandler}/getAllMembers`,
		UPDATE_SEATS_LIST: `${codeRabbitHandler}/updateSeats`,
	}
}

export const getAPIConfig = () => {
	return {
		headers: {
			common: {
				"Content-Type": "application/json",
				Accept: "application/json",
				"Cache-Control": "no-cache",
			},
		},
	}
}
