import Loader from "@/components/Loader/Loader"
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { trpc } from "@/trpc"
import { PROVIDERS, useProvider } from "@/utils/providers"
import { getSelectedOrg, sentryCaptureException } from "@/utils/utils"
import { zodResolver } from "@hookform/resolvers/zod"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import z from "zod"
const formSchema = z.object({
	delete: z.string().refine(value => value === "delete", {
		message: "The string must be 'delete'",
	}),
})

interface DeleteAccountModalProps {
	readonly open: boolean
	readonly onOpenChange: (open: boolean) => void
	readonly onDeleteOrg?: () => void
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({
	open,
	onOpenChange,
	onDeleteOrg,
}) => {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			delete: "",
		},
	})

	const [deleteAccount, setDeleteAccount] = React.useState<
		boolean | undefined
	>()

	const { provider } = useProvider()

	const deleteAccountOrOrganization =
		trpc.organizations.deleteOrganization.useMutation({
			onSuccess: ({ isSuccess: response }) => {
				if (response) {
					setDeleteAccount(!deleteAccount)
					onDeleteOrg?.()
					toast.success("Account Deleted Successfully")
				}
			},
			onError: error => {
				sentryCaptureException(
					`Failed to delete organization for provider ${provider}`,
					error,
				)
				toast.error("Failed to delete the account... Please Contact Support.")
			},
		})

	useEffect(() => {
		if (deleteAccountOrOrganization.error) {
			toast.error(
				"Failed to delete the account: " +
					deleteAccountOrOrganization.error.message,
			)
		}
	}, [deleteAccountOrOrganization.error])

	const handleSubmit = () => {
		const role = getSelectedOrg()?.role
		if (role && role === "admin") {
			deleteAccountOrOrganization.mutate({ role: role })
		} else {
			toast.error("Only admin role users can delete the organization.")
		}
	}

	useEffect(() => {
		if (open) {
			setDeleteAccount(undefined)
			form.reset()
		}
	}, [open])

	const isLoading = deleteAccountOrOrganization.isLoading

	return (
		<Dialog open={open} onOpenChange={onOpenChange}>
			<DialogContent
				onPointerDownOutside={e => {
					e.preventDefault()
				}}
				className="sm:max-w-md"
			>
				{isLoading && <Loader />}
				<DialogHeader>
					<DialogTitle>
						Are you sure you want to delete your account?
					</DialogTitle>
					<DialogDescription className="py-4 font-normal">
						{provider === PROVIDERS.GITHUB || provider === PROVIDERS.GITLAB ? (
							<span>
								This action <strong>cannot</strong> be undone. This will{" "}
								<strong>immediately</strong> delete the CodeRabbit account for
								this organization from CodeRabbit servers. This data includes
								any learnings, reports, and stored settings. You must have admin
								access for the deletion to be successful.
							</span>
						) : (
							<span>
								This action <strong>cannot</strong> be undone. This will{" "}
								<strong>immediately</strong> delete the CodeRabbit account and{" "}
								<strong>ALL</strong> organizations from CodeRabbit servers. This
								data includes any learnings, reports, and stored settings.
							</span>
						)}
					</DialogDescription>
				</DialogHeader>

				{!deleteAccount && (
					<form onSubmit={form.handleSubmit(handleSubmit)}>
						<div className="pb-4">
							<Input
								id="confirm-delete"
								{...form.register("delete")}
								className={`mt-0.5 ${
									form.formState.errors.delete
										? "border-red-500"
										: "border-gray-300"
								}`}
								autoComplete="off"
							/>
							{form.formState.errors.delete && (
								<p className="mt-2 font-inter text-sm text-destructive">
									{form.formState.errors.delete.message}
								</p>
							)}
							<DialogDescription className="py-4 font-mono font-normal">
								To confirm, type <strong>"delete"</strong> in the field above.
							</DialogDescription>
						</div>
						<DialogFooter>
							<Button
								className="px-4 py-2 font-inter font-semibold"
								type="submit"
							>
								Delete Account
							</Button>
						</DialogFooter>
						<Accordion type="single" collapsible className="py-4 text-sm">
							<AccordionItem value="item-2">
								<AccordionTrigger>
									Additional required steps to remove CodeRabbit
								</AccordionTrigger>
								<AccordionContent>
									<p className="mb-1">
										You must remove OAuth access to CodeRabbit for your
										organization.
									</p>
									<p className="mb-1">
										Github users will also need to remove the GitHub app from
										the organization.
									</p>
									<p className="mb-1">
										GitLab users will also need to remove the CodeRabbit
										webhook, and the CodeRabbit user from their project(s).
									</p>
									<p className="mb-1">
										For more details, please visit our{" "}
										<a
											className="text-blue-600 underline"
											href="https://docs.coderabbit.ai/guides/delete-account"
											target="_blank"
											rel="noopener noreferrer"
										>
											documentation
										</a>{" "}
										which provides all the steps required to completely
										off-board CodeRabbit.
									</p>
								</AccordionContent>
							</AccordionItem>
						</Accordion>
					</form>
				)}

				{deleteAccount && (
					<DialogFooter className="sm:justify-end">
						<DialogClose asChild>
							<Button type="button" variant="secondary">
								Close
							</Button>
						</DialogClose>
					</DialogFooter>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default DeleteAccountModal
