import { Box } from "@mui/material"
import {
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState,
	type FC,
	type PropsWithChildren,
} from "react"

import ErrorLayout from "@/components/Loader/ErrorLayout"
import Loader from "@/components/Loader/Loader"
import { logger } from "@/lib/utils"
import {
	ENABLED_GRAFANA,
	GRAFANA_APP_NAME,
	GRAFANA_PROVIDER_VIEWPORT_ID,
} from "./consts"
import {
	GrafanaContext,
	grafanaContextInitial,
	grafanaReducer,
	useGrafanaContext,
	type GrafanaContextProps,
	type GrafanaReducer,
} from "./grafana-context"
import { useRootGrafanaApp } from "./hooks"
import { fetchInterceptor } from "./intercept-request"
import { GrafanaUtils } from "./utils"

if (ENABLED_GRAFANA) {
	fetchInterceptor()
}

export const GrafanaProvider: FC<PropsWithChildren> = ({ children }) => {
	const [state, dispatch] = useReducer<GrafanaReducer>(
		grafanaReducer,
		grafanaContextInitial,
	)

	const value: GrafanaContextProps = useMemo(
		() => ({
			...state,
			action: dispatch,
		}),
		[state],
	)

	return (
		<GrafanaContext.Provider value={value}>
			<RootGrafanaApp />
			{children}
		</GrafanaContext.Provider>
	)
}

export const RootGrafanaApp: FC = () => {
	const containerRef = useRef<HTMLElement>(null)

	const { action, grafanaUtils } = useGrafanaContext()

	useEffect(() => {
		if (!action || !containerRef.current) return

		// store inside grafana context
		action({
			type: "SET_GRAFANA_PROVIDER",
			payload: {
				name: GRAFANA_APP_NAME,
				container: containerRef.current,
				entry: GrafanaUtils.DEFAULT_ENTRY,
				configuration: {
					singular: true,
					prefetch: true,
					sandbox: {
						experimentalStyleIsolation: true,
					},
				},
			},
		})
	}, [action])

	useRootGrafanaApp(containerRef).load()

	const [isLoading, setIsLoading] = useState(true)
	const [isError, setIsError] = useState(false)

	const mfeApp = grafanaUtils?.appData?.app

	useEffect(() => {
		if (!mfeApp) return

		mfeApp.mountPromise
			.then(() => {
				logger.info("Grafana root app mounted successfully")
				setIsLoading(false)
			})
			.catch(() => {
				logger.error("Failed to mount mfe app. Analytics API not available.")
				setIsLoading(false)
				setIsError(true)
			})
	}, [mfeApp])

	return (
		<>
			{isLoading && !isError && <Loader />}
			{isError && <ErrorLayout />}
			<Box ref={containerRef} id={GRAFANA_PROVIDER_VIEWPORT_ID} hidden />
		</>
	)
}
