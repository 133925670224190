import { httpBatchLink } from "@trpc/client"
import {
	createTRPCReact,
	type CreateTRPCReact,
	type inferReactQueryProcedureOptions,
} from "@trpc/react-query"
import type { CreateClient } from "@trpc/react-query/shared"
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server"
import type { AppRouter } from "../../coderabbitHandler/src/routers/index"
import { getSelectedOrg } from "./utils/utils"

const CODERABBITAI_ORGANIZATION_HEADER = "x-coderabbitai-organization"

export const trpc: CreateTRPCReact<AppRouter, unknown, null> =
	createTRPCReact<AppRouter>({
		abortOnUnmount: true,
	})

export const trpcClient: ReturnType<CreateClient<AppRouter>> =
	trpc.createClient({
		links: [
			httpBatchLink({
				url: import.meta.env.VITE_API_URL,
				headers() {
					return {
						Authorization: `Bearer ${sessionStorage.getItem("accessToken") || ""}`,
						[CODERABBITAI_ORGANIZATION_HEADER]: getSelectedOrg()?.id || "",
					}
				},
			}),
		],
	})

export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>
export type RouterInputs = inferRouterInputs<AppRouter>
export type RouterOutputs = inferRouterOutputs<AppRouter>
