import { Switch } from "@/components/ui/switch"
import type {
	GHHandlerGetSlackConnectionResp,
	GHHandlerRemoveSlackConnectionBody,
	GHHandlerRemoveSlackConnectionResp,
	PlatformChannel,
} from "@/typings/githubActionsHandler.ts"
import { getSelectedOrg } from "@/utils/utils.ts"
import type { AxiosResponse } from "axios"
import axios from "axios"
import queryString from "query-string"
import React, { useEffect, useState } from "react"
import { FaInfoCircle } from "react-icons/fa"
import { toast } from "react-toastify"
import slackLogo from "../../../../assets/slack_logo.png"
import ButtonRegular from "../../../../components/ButtonRegular/ButtonRegular"
import Combobox from "../../../../components/Combobox/Combobox"
import Loader from "../../../../components/Loader/Loader"

interface SlackIntegrationCardProps {
	readonly channel: PlatformChannel["id"]
	readonly onChangeChannel: (channel: PlatformChannel["id"]) => void
	readonly isSelected: boolean
	readonly onSelectChange: (e: boolean) => void
}

const SlackIntegrationCard: React.FC<SlackIntegrationCardProps> = ({
	channel,
	onChangeChannel,
	isSelected,
	onSelectChange,
}) => {
	const [attemptedConnection, setAttemptedConnection] = useState<boolean>(false)
	const [isLoading, setIsLoading] = React.useState<boolean>(false)
	const [refreshConnection, setRefreshConnection] =
		React.useState<boolean>(true)
	const [channels, setChannels] = React.useState<PlatformChannel[]>([])
	const [hasAccessToken, setHasAccessToken] = React.useState<boolean>(false)

	const selectedOrg = getSelectedOrg()

	const getSlackConnection = () => {
		setIsLoading(true)

		return axios
			.get<GHHandlerGetSlackConnectionResp>(
				`${import.meta.env.VITE_GITHUB_FUNC_URL}/getSlackConnection`,
				{
					headers: {
						Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
						"x-coderabbit-organization": selectedOrg?.id,
					},
				},
			)
			.then(response => {
				setHasAccessToken(true)
				setChannels([...response.data.channels])
			})
			.catch(error => {
				setHasAccessToken(false)
				setChannels([])
				if (error.response?.status === 404) {
					return
				}
				toast.error(
					"Failed to get slack connection: " + error.response?.data?.message ||
						error,
				)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const connectSlack = () => {
		const params = queryString.stringify({
			client_id: import.meta.env.VITE_SLACK_BOT_CLIENT_ID,
			/*
				Scope for posting messages:  https://api.slack.com/scopes/chat:write.public
				Scope for fetching channels: https://api.slack.com/methods/conversations.list#scopes
			*/
			scope: [
				"chat:write",
				"chat:write.public",
				"channels:read",
				"groups:read",
				"im:read",
				"mpim:read",
			].join(" "),
			redirect_uri: import.meta.env.VITE_SLACK_REDIRECT_URI,
			orgId: sessionStorage.getItem("org_id"),
		})

		window.open(`https://slack.com/oauth/v2/authorize?${params}`, "_blank")
	}

	const disconnectSlack = () => {
		setIsLoading(true)

		return axios
			.post<
				GHHandlerRemoveSlackConnectionResp,
				AxiosResponse<GHHandlerRemoveSlackConnectionResp>,
				GHHandlerRemoveSlackConnectionBody
			>(
				`${import.meta.env.VITE_GITHUB_FUNC_URL}/removeSlackConnection`,
				{},
				{
					headers: {
						Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
						"x-coderabbit-organization": selectedOrg?.id,
					},
				},
			)
			.then(() => {
				toast.success("Slack connection removed successfully")
				setHasAccessToken(false)
				setChannels([])
				onChangeChannel("")
				onSelectChange(false)
			})
			.catch(error => {
				toast.error(
					"Failed to remove slack connection: " +
						error.response?.data?.message || error,
				)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const handleConnectSlack = () => {
		if (!attemptedConnection) {
			connectSlack()
		} else {
			setRefreshConnection(true)
		}

		setAttemptedConnection(!attemptedConnection)
	}

	useEffect(() => {
		if (refreshConnection) {
			setRefreshConnection(false)
			void getSlackConnection()
		}
	}, [refreshConnection])

	if (isLoading) {
		return <Loader />
	}

	return (
		<div className="rounded-lg border bg-white p-6">
			<div className="flex items-center justify-between gap-2">
				<div className="flex items-center">
					<div className="flex h-16 flex-shrink-0 items-center justify-center rounded-lg border p-1.5">
						<img width={50} src={slackLogo} alt="Slack logo" />
					</div>
					<div className="ml-5">
						<p className="font-weight-600 text-base text-crb-text-primary">
							Slack
						</p>
						{hasAccessToken && (
							<button
								className="text-start text-crb-primary hover:underline"
								onClick={disconnectSlack}
							>
								Disconnect slack
							</button>
						)}
					</div>
				</div>
				<div className="h-fit">
					{!hasAccessToken ? (
						<ButtonRegular
							title={attemptedConnection ? "Check connection" : "Connect"}
							onClick={handleConnectSlack}
						/>
					) : (
						<Switch checked={isSelected} onCheckedChange={onSelectChange} />
					)}
				</div>
			</div>
			<p className="mt-4 text-sm text-crb-text-tertiary">
				{hasAccessToken
					? "Configure the slack channel to send the report to"
					: "Connect your slack workspace to setup the channel for sending the report"}
			</p>
			{hasAccessToken && (
				<>
					<div className="font-500 mt-6 font-poppins text-sm">
						Select the channel
					</div>
					<Combobox
						options={channels}
						value={channel}
						onChange={channel => {
							onChangeChannel(channel)
						}}
						placeholder="No channel selected"
					/>
				</>
			)}
			{hasAccessToken && (
				<div className="mt-2 flex gap-1 text-xs text-gray-500">
					<FaInfoCircle className="mt-0.5 flex-shrink-0 text-gray-400" />
					For sending the report to a private channel, make sure that the bot is
					added to the channel.
				</div>
			)}
		</div>
	)
}

export default SlackIntegrationCard
